<template>
  <NavQuestion
    :heading="heading"
    :number="'3'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'No'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-no.png'"
        :selected="funeral.funeral_organs === 'No'"
        @selected="save('No')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Yes, for transplants'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-therapy.png'"
        :selected="funeral.funeral_organs === 'Yes, for therapy'"
        @selected="save('Yes, for therapy')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'Yes, for research'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-research.png'"
        :selected="funeral.funeral_organs === 'Yes, for research'"
        @selected="save('Yes, for research')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'d'"
        :option="'Yes, for research & transplant'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-both.png'"
        :selected="funeral.funeral_organs === 'Yes, for research & therapy'"
        @selected="save('Yes, for research & therapy')"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/funeral/style'"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'FuneralOrganDonor',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  computed: {
    funeral() {
      return this.$store.getters.funeral
    },
    isValidated() {
      if (this.funeral.funeral_organs) return true
      return false
    },
    heading() {
      return 'Would you like to become an organ donor?'
    },
    subHeading() {
      return ' If you would like to become a donor, we also recommend adding your name to the NHS Organ Donor Register and telling your close family.'
    },
    forwardTo() {
      return '/funeral/plan'
    }
  },
  methods: {
    save(value) {
      if (this.funeral) {
        this.$store.commit('funeral', {
          funeral_organs: value
        })
      }
    }
  }
}
</script>
